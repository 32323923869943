<template>
    <div>

		<div class="title">
			<p class="main">Murderous Contagion by Mary Dobson</p>
			<div class="subheadings">
				<p class="published-date">17th August 2024</p>
			</div>
		</div>

		<div class="article">
			<p>“I read a book a week, so…”. Chris Finch from The Office may be the consummate prick and bullshitter, but I can at least say I have kind of matched his insane claim to read a book a week once this year. Albeit I’m yet to throw a kettle over a pub.</p>

			<p>Continuing my main themes this year of Statistics and Death, I’ve just finished the interesting but depressing Murderous Contagion by Mary Dobson. After getting semi-involved in a campaign to reduce the cost of tuberculosis testing equipment and medication after reading the incredibly frightening Phantom Plague earlier this year, I wanted to know more about where we currently stand with various diseases. Mary Dobson’s collection on the history of major contagions is a brilliant primer on disease infectious and non-infectious throughout history, up to the (pre-Covid) present day.</p>

			<p>A couple of general points before I go on to the things that I found especially interesting. The book is in four parts: bacterian, viral, parasitic, and lifestyle and each one reads like an essay of middling length on each disease. They follow a regular pattern of history, discovery, treatment (where applicable) and current day position. A frustrating element of this is repetition. The same figures appear again and again as if for the first time (I’m looking at you John Lister) and the same concluding points are made at the end of each chapter, namely that most disease affects the worst off worst. This is obviously a point that bears repeating and the inequity of disease, and the extent to which most people actually die of poverty or social stratification, is a major part of my interest in this topic. But I felt Dobson could have concluded the book more satisfactorily by reflecting on the point at large at some point. She doesn’t set out to make a social case for inequality in health care so perhaps I’m being unfair. But I found myself feeling again and again that the underlying point is that healthcare <i>is not</i> equitable. Just repeating it doesn’t make it more impactful.</p>

			<p>Diseases like malaria, it almost goes without saying, feature heavily in Murderous Contagion and provide some of the most frustrating examples of treatment and eradication attempts gone awry. Once the disease vector of mosquitoes was discovered, immediate attempts began to eradicate or otherwise thwart the mosquito. Be it bed nets, pre-exposure quinine, or the liberal spraying of DDT, the efforts undertaken were often extreme and borne of Victorian and Edwardian confidence that science could triumph over any maladie. DDT proves an especially frustrating attempt at control. Effective as it was at killing mosquitoes, it proved to be highly environmentally toxic and also an insecticide that produced rapid and robust immunity in mosquitoes. So not only do we still have malaria, but we also have a generation of super mosquitoes that can evade insecticides. A recent development, even more recent at the time of publication, has been the release of genetically modified mosquitoes that cannot reproduce, or reproduce in such a way as to prevent their offspring from carrying diseases. I’m hugely excited by our ability to bend nature to our will, but I am concerned about the next DDT or similar. The law of unforeseen consequences lies heavy on the head.</p>

			<p>But this leads on to some almost unbelievable missteps in disease control, often done with the best of intentions. At the onset of the HIV/AIDS pandemic, it was declared by people closest to the problem that communities in Africa should be excluded from WHO plans for education and treatment. Seemingly born from the mistaken idea that ‘they don’t have wristwatches’, it was felt by some that attempting to deliver AZT or similar treatments was unlikely to work and therefore shouldn’t be tried. How fucking dare we, eh? Similarly, we now know that in our attempts to vaccinate populations, also in Africa, against other communicable diseases, medics also spread infections like HIV by using the same needle over and over on hundreds of people. Somewhat annoyingly, Dobson refers to these missteps as ‘tragic’ - and I disagree. To call them tragedies is to suggest that they were unavoidable and unforeseeable. They weren’t tragedies, they were at best reckless treatments of poorly served communities based on a bit of a white saviour complex. Sometimes it’s good to say “Actually, we have better information and will unfortunately have to impose a programme on this group of people for their own good” but it often isn’t. And organisations like the Gates Foundation and Partners in Health have had huge success by doing what is in their name - partnering - for better outcomes. Ebola is a good example of partnering with communities to work around traditional burial practices that were actually causing contagion in a way that respects culture.</p>

			<p>Social attitude to disease is probably best shown by the history of leprosy as outlined by Dobson. We think of leprosy as a highly stigmatised and traumatic disease that Jesus cured people of but it’s actually very modern and with us today. It turns out it’s extremely difficult to catch, and relatively easy to treat. What’s more, in earlier centuries it was often actually seen as a holy disease that afflicted people marked out by God as special (hence the Jesus connection). Leper ‘colonies’ were often not to exclude, but to gather and to treat sufferers. It’s interesting the extent to which, especially in the west, we bring our own biases to things and despite the above issue with some language, this is actually something Dobson explores really comprehensively and interestingly.</p>

			<p>There are obviously some stories of great personal triumph in the history of medicine and disease. The work of Lister and Koch in pioneering germ theory, antiseptic practice and identifying the viruses and bacteria that cause illness has to be acknowledged as absolutely revolutionary on several fronts. The impact of public private partnership has been immense with the Gates Foundation in particular receiving high, and in my opinion warranted, praise for its work on reducing inequality in healthcare and outcomes. But I feel the need to revisit the earlier example of needle sharing to talk about some of the problems with our attempts to treat health as a global problem. Dobson explains how diseases like Sleeping Sickness and other illnesses seemingly endemic in developing countries and developing countries only become instances of people (though not really the Gates Foundation in fairness) treating people and not disease. A kind of ugly stepsister of treating the symptom not the disease.</p>

			<p>If there is one thing to take away from Murderous Contagion, and Phantom Plague for that matter, it’s that most people actually do die of poverty. That applies to poorer people in western countries as much as it applies to people in the global south. If we want better health outcomes globally, and we should even selfishly want that, we need to stop thinking about disease as a person becoming ill and instead think about it the way we think about other systemic problems or what might be called problems of the social order. Sure, buy more bed nets. That’s great. Give more money to the Global Fund Against Malaria. That’s excellent. But more importantly, think about why these things are happening. What are the systemic challenges that mean disease mostly affects the global south? How can we mitigate those or remove them? We can find all the vaccines in the world but as Covid taught us, without appropriate distribution networks and refrigeration infrastructure, a lot of them will be useless. We have a fight on our hands against disease. But it’s not a fight to stop individual people from getting ill - it’s a fight against disease as an outcome of inequality. The more we recognise that (and we are) the better equipped we’ll be to fight.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "MurderousContagionArticle"
	}

</script>


<style>

</style>