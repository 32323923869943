<template>
  <div id="app">
    <LetrangerArticle/>
    <GreatExpectationsArticle/>
    <ProphetSongArticle/>
    <TeamOfRivalsArticle/>
    <TomorrowAndTomorrowAndTommorowArticle/>
    <MurderousContagionArticle/>
    <InvertingThePyramidArticle/>
    <NoiseArticle/>
    <FranceArticle/>
    <NudgeArticle/>
    <OurLastBestChanceArticle/>
    <TheLionAndTheUnicornArticle/>
    <EscapeArticle/>
    <NumbercrunchArticle/>
    <FourWaysOfThinkingArticle/>
    <ExpectedGoalsArticle/>
    <PhantomPlagueArticle/>
    <UnreasonableHospitalityArticle/>
    <BronzeAgeArticle/>
    <WashingtonArticle/>
  </div>
</template>

<script>
  import WashingtonArticle from '@/components/partials/articles/WashingtonArticle.vue'
  import BronzeAgeArticle from '@/components/partials/articles/BronzeAgeArticle.vue'
  import UnreasonableHospitalityArticle from '@/components/partials/articles/UnreasonableHospitalityArticle.vue'
  import PhantomPlagueArticle from '@/components/partials/articles/PhantomPlagueArticle.vue'
  import ExpectedGoalsArticle from '@/components/partials/articles/ExpectedGoalsArticle.vue'
  import FourWaysOfThinkingArticle from '@/components/partials/articles/FourWaysOfThinkingArticle.vue'
  import NumbercrunchArticle from '@/components/partials/articles/NumbercrunchArticle.vue'
  import EscapeArticle from '@/components/partials/articles/EscapeArticle.vue'
  import TheLionAndTheUnicornArticle from '@/components/partials/articles/TheLionAndTheUnicornArticle.vue'
  import OurLastBestChanceArticle from '@/components/partials/articles/OurLastBestChanceArticle.vue'
  import NudgeArticle from '@/components/partials/articles/NudgeArticle.vue'
  import FranceArticle from '@/components/partials/articles/FranceArticle.vue'
  import NoiseArticle from '@/components/partials/articles/NoiseArticle.vue'
  import InvertingThePyramidArticle from '@/components/partials/articles/InvertingThePyramidArticle.vue'
  import MurderousContagionArticle from '@/components/partials/articles/MurderousContagionArticle.vue'
  import TomorrowAndTomorrowAndTommorowArticle from '@/components/partials/articles/TomorrowAndTomorrowAndTommorowArticle.vue'
  import TeamOfRivalsArticle from '@/components/partials/articles/TeamOfRivalsArticle.vue'
  import ProphetSongArticle from '@/components/partials/articles/ProphetSongArticle.vue'
  import GreatExpectationsArticle from '@/components/partials/articles/GreatExpectationsArticle.vue'
  import LetrangerArticle from '@/components/partials/articles/LetrangerArticle.vue'


  export default {
  name: 'App',
  components: {
    WashingtonArticle,
    BronzeAgeArticle,
    UnreasonableHospitalityArticle,
    PhantomPlagueArticle,
    ExpectedGoalsArticle,
    FourWaysOfThinkingArticle,
    NumbercrunchArticle,
    EscapeArticle,
    TheLionAndTheUnicornArticle,
    OurLastBestChanceArticle,
    NudgeArticle,
    FranceArticle,
    NoiseArticle,
    InvertingThePyramidArticle,
    MurderousContagionArticle,
    TomorrowAndTomorrowAndTommorowArticle,
    TeamOfRivalsArticle,
    ProphetSongArticle,
    GreatExpectationsArticle,
    LetrangerArticle
  }
}
</script>

<style>


@media only screen and (max-width: 767px) {
  .article {
    max-width: 80%;
    min-width: 80%;
    margin: 0 auto;
  }

  .article p {
    max-width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }

  .title {
    width: 100%; /* Ensure the title div takes up full width */
    background: #434342;
    padding-bottom: 20px;
    padding-top: 20px;
  }
}


.title .main {
    min-width: 100%; /* Ensure the title div takes up full width */
    background: #434342;
    padding-bottom: 20px;
}

.subheadings {
    display: flex; /* Enable Flexbox */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Align items vertically in the middle */
    width: 100%; /* Ensure subheadings take up full width */
}

.subheadings p {
    margin: 0 5px; /* Add some space between the p elements */
    display: inline-block; /* This is optional since flex items are inline-level by default */
    text-align: center;
    font-size: 14px !important;
    background: #434342;
}

.published-date {
  color: white !important;
}

.affiliate-link {
  color: #FCBA03 !important;
}

.title p.published-date,
.title p.affiliate-link {
    padding: 4px; /* Removes default margin from p elements */
}

.title {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  width: 100%;
  background: #434342;
  padding-bottom: 20px;
  padding-top: 20px;
}

.title p {
  text-align: center;
  font-size: 30px;
  color: #FCBA03;
  line-height: 125%;
  display: inline-block;
}

.article p {
  font-family: "Lustria", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  background: #434342;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 16px;
  margin: auto;
  max-width: 40%;
  color: white;
}

li {
  font-size: 16px;
}

a {
  color: #FCBA03 !important;
}

a:hover {
  color: #ffcf4a !important;
  text-decoration: none !important;
}

</style>
