<template>
    <div>

		<div class="title">
			<p class="main">Great Expectations by Charles Dickens</p>
			<div class="subheadings">
				<p class="published-date">24th October 2024</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>God this took forever. Not because I wasn’t enjoying it - I was - but just because I find it so much harder to get into fiction. I constantly find myself unable to enjoy the semi-episodic nature of a lot of fiction because I know that the main thrust of the story is going to come in the last 100 pages or so. One of the reasons I wanted to read Great Expectations was because I knew it had originally been published periodically, and I thought that that act structure might make it easier for me to get over my “Right but when is it all going to kick off?” instincts.</p>

			<p>As usual I’m not going to retell the whole plot here but suffice it to say that it’s a coming of age story about a young boy named Pip, his relationships with people around him who are all from different social classes (more on that later) and how he tries to become the most honourable version of himself he can be. The main driving force is his changing idea of what it means to be “honourable” with each section showing a different interpretation of what it might mean to be a good person and how Pip tries to fit in with these new expectations.</p>

			<p>I was definitely drawn into that aspect of the story. It goes without saying that a huge part of the continued charm of Dickens (whom I read nothing of until this) is his commentary on the society he found around him. It’s undeniable the continuing reveals of people thought to be good or with Pip’s best interests at heart actually being actively against him or simply passive as he progresses socially is Dickens asking us to consider what it actually is to be a “gentleman”. Gentleman is used throughout as almost a synonym for “good guy” and yet every gentleman that Pip encounters seems to have some kind of agenda against him.</p>

			<p>There are really only three allies that Pip has in the book: Joe, Herbert Pocket, and Wemmick. Even then, he has a fist fight in childhood with Herbert, and Wemmick is an almost split personality at home and at his work. But these three are broadly looking out for him. Joe is a blacksmith, Herbert is not wealthy but not quite poor either and seeks to make something of himself, while Wemmick is an established lawyer’s clerk on a good if not outrageous salary. We can see from these three that Dickens is not saying that wealth (or at least financial stability) is not a barrier to goodness, but he is certainly hinting at the old adage that “money is the root of all evil”.</p>

			<p>The people around Pip who are most actively working against him could be argued to be Mrs Havisham, the wealthy widower who for a long time Pip believes to be his benefactor; Pumblechook who Pip takes an instant dislike to; and (controversially) Magwitch who it turns out is his true benefactor. Mrs Havisham is a manipulative person who seeks to pull the strings of Pip and her adoptive daughter Estella’s lives. She appears to have no ability to care for a person but Pip ignores this initially while he believes that Mrs Havisham is looking out for him. Pumblechook is simply distasteful and the less said about him the better. Magwitch has a similar outlook to Mrs Havisham in that he seeks to turn Pip into a “perfect gentleman” to prove that he could do it despite a criminal background. Again, two of these are wealthy and one becomes wealthy. A hammer is meeting the head of a nail.</p>

			<p>Probably the character about whom the least is revealed or changes throughout the novel is Pip’s kind of adoptive father but also kind of uncle, Joe Gargery. Pip initially believes he will apprenticed to Joe. He calls him his best friend and knows he is a true ally. Despite this, as he progresses he becomes ashamed of Joe and his “savagery”. He keeps Joe away from his more well to do companions and actively avoids him himself later. We can see most clearly from his view of Joe what Pip thinks it means to be good. As a young boy, he admires Joe but covets a more comfortable life. He doesn’t think Joe is especially unambitious, but he does with his family had a bit more about them. As he grows up and becomes detached from his family he comes to prefer wealthier company and view this kind of life as inherently a sign of goodness. With more time Pip gets more exposure to the ugly side of this life. He discovers the conspiracy that led Mrs Havisham to her isolation and therefore to her mocking of him. He discovers that his change in circumstance is not through the kind of goodly wealthy benefactor he expected. This changes his view of morality again and as he finds himself back where he started he seems to have more of a sense of contentment. He has a lot of work to do, but he views it as honest work rather than wealth for its own sake.</p>

			<p>A huge plot point and the one that ends the book is Pip’s relationship with Estella. He first meets her as a young boy and finds her aloof and uninterested in him which of course leads to an almost all consuming infatuation. This infatuation lasts most of his youth and into his adult life even as Estella shows zero interest in him, and actively courts men Pip has come to hate. However, as Pip discovers Estella’s true history and as they both get older, how her life has ended up, he develops a sympathy for her and someone who has in reality been treated similarly to him. Especially by the likes of Mrs Havisham and Pip’s rich companions from his adolescence.</p>

			<p>I’m not going to dwell on the ending of the plot as it is a) ambiguous and b) was apparently re-written several times to suit the desires of readers. It also occurs to me that, despite being a story told for its own sake, the actual point of Great Expectations is Pip’s moral development and how we come to view reality with him. His eventual realisation of the value of intrinsic goodness and kindness is the heart of the book. And that’s where I want to leave it.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "GreatExpectationsArticle"
	}

</script>


<style>

</style>