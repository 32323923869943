<template>
    <div>

		<div class="title">
			<p class="main">Escape by Marie Le Conte</p>
			<div class="subheadings">
				<p class="published-date">19th April 2024</p>
				
				
			</div>
		</div>
		<div class="article">
			<p>It actually only took me about 8 hours to get through Marie Le Conte’s second book, Escape, but it took about a month because I got completely sidetracked by replaying both The Last Of Us games more or less every evening. When I did get into it, Escape didn’t make me long for the lost internet - it just made me sad. The book chronicles the rise of the internet with a generation, my generation, in the early 2000s and finishes up with the internet where it is: Dead, unfun, and soulless.</p>

			<p>There are a couple of passages I want to draw attention to but by way of a starter, I, like Le Conte, was an extremely online teenager. I also scrawled through MySpace band pages, and spent hours on MSN Messenger, and read niche blogs, and used forums. I do none of those things now with a couple of notable exceptions and it was deeply strange to read someone else’s account of their life online and the loss they feel at the communities that are now dead. The central premise is that the internet used to feel like a special secret place. But now that everyone is online at every moment it feels more like the nearest gastro-pub. You used to love it because they didn’t ID anyone and it was cheap. But now they do brunch and you can’t move online for pictures of the food. I want my extremely fizzy pint of Tennant’s from a dirty glass back.</p>

			<p>There’s a Tom Scott video from around 2010 about what the internet might be like in 2020. One big miss from that video is that he doesn’t really predict that all online communities would move into platforms. It used to be that everyone had their own little self hosted niche forum. These are much more likely now to be Facebook groups, subreddits, or Discord servers. One exception I kept finding myself thinking about here though was football. I have been a Celtic supporting lurker on the Rangers forum Follow Follow for almost 15 years. As an aside, people like me on that forum are referred to as Timposters, a portmanteau of Tim, meaning Catholic or Celtic fan, and imposter - this is a joke that I find constantly hilarious much to my annoyance. Similarly, Celtic forums like Kerrydale Street mirror forums for other clubs up and down the country. The weird thing though is that I spend most of my football time online on r/ScottishFootball. This does have a community feel because it’s quite small. But it’s still on Reddit. Le Conte talks about how the blogosphere felt this way. I certainly remember this from political blogs. But in the modern age, this is now just Twitter. Or increasingly each individual's Substack with no interaction.</p>

			<p>There has been no development on the internet (said the professional digital product manager) for about 15 years. We still use Instagram, Facebook, and Twitter. Yeah things like Snapchat and TikTok and Vine (RIP king of kings) had their moments but the big three reign supreme. YouTube too if you want to get broad about it. It doesn’t feel to me as if this will change. Everyone is now online and has their own little space. These platforms won’t die because they are in, in the truest sense, too big to fail. Phones get a little faster, UIs get a little more minimalist, everything is still basically Facebook, Instagram and Twitter. I came off Twitter about a year ago and, barring one day back when Liz Truss resigned, I do not miss it. Seemingly more and more people feel the same.</p>

			<p>The main things that made me sad reading this book though were two fold: It’s no longer special to be extremely online, and the internet is not as permanent as we thought. Once upon time, I took a certain smug satisfaction from knowing quite a lot about meme culture. Enough that I was annoyed (like Le Conte) by the far right co-opting of Pepe the Frog and Wojak. But meme culture now just is culture. It was maybe naive of me to think that the internet could keep growing but stay a subculture but I did think that - and I’m sad it didn’t. We used to say the internet was written in ink but increasingly it isn’t. Things are much more ephemeral than we thought. Le Conte remarks that whole relationships which took place in messages on dead platforms are just gone. There are formative conversations that I had that I can never see again. To an extent this has always been true but I was surprised by how much that realisation got to me. I am an extreme digital hoarder - I back up my emails. But there are years that I can never revisit. I keep a diary in Google Docs and have done for almost a decade but the first thing I’m going to do after publishing this is back it up.</p>

			<p>The last 50 pages or so of Escape just got painfully nostalgic, starting with the ‘lolitics’ corner of Tumblr. Le Conte reminded me of the practice at the time of ranking Labour politicians not by their policy positions or electoral viability but by how likely they were to hold your hair back while you were sick in a nightclub bathroom. Andy Burnham, much to my annoyance, always came out on top of those. There is no denying that my involvement in these weird little self referential internet places shaped a lot of my personality and a lot of my humour. I love finding people in real life who remember things like the original Vlogbrother run in 2007, or the Milifandom in 2015, sharing a slightly embarrassed moment, and then feeling unbelievably sorry that that kind of fun weird internet space doesn’t exist anymore. Or maybe it does, but I’m just not weird or young or cool enough to find it anymore. Maybe there’s a Discord where people are making jokes about Hugh Gaitskell. I don’t know. I doubt it.</p>

			<p>There is a (singular pronoun, really?) serious problem with the internet that goes along with what exactly I found amazing about it when I was growing up. If you’re the one sad saddo who loves Elliott Smith in your school, you are alone. But then you find a blog full of sad saddos and everything is great! This applies as much to Elliott Smith or Ben Folds as it does to alt-right conspiracy. Le Conte mentions this almost in passing like an unfortunate side effect but there is a recent example of an online community making its own space. The subreddit r/the_donald which I can only assume started ironically eventually morphed into a vortex of Q-Anon conspiracy and hate speech. When Reddit belatedly declared it in breach of their terms of service, it was reborn as thedonald.win - a self hosted forum with no moderation or oversight. I’m not saying we should get people into walled gardens so we can keep an eye on them. I actually think I fundamentally disagree with that concept, though I’m not sure. What I am saying is more a warning to myself not to get too nostalgic. Yes, the early internet was great. But it’s gone now and it’s never coming back. But the legacy of it remains, and we’ll see its impact more than ever in the elections of 2024. I just wanted some memes about the Labour Party and now we have fascists. What chance have you got?</p>
		</div>

    </div>
</template>


<script>

	export default {
		name: "EscapeArticle"
	}

</script>


<style>

</style>