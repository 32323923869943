<template>
    <div>

		<div class="title">
			<p class="main">L'étranger by Albert Camut</p>
			<div class="subheadings">
				<p class="published-date">1st November 2024</p>
				
				
			</div>
		</div>

		<div class="article">
			<p>I’ll finish this year by finishing a book I started about 18 months ago. I’ve been learning French since 2020 and was given a copy of L’étranger to help with this. I found reading Camut in French extremely difficult and tiring (I expect I wouldn’t find the English much easier!) so at a rate of a few pages a week while taking notes I eventually made it through. It occurs to me that I should maybe have started with Harry Potter or one of the Madeline books or something but here we are. 180 or so pages of existentialist fiction later, my French is slightly better. Maybe. If I’m asked how I feel about the absurdity of the human experience. Very formally. And slowly. And I can take notes during the conversation.</p>

			<p>It goes without saying that with L’étranger, the plot isn’t really the point but it bears summarising. After the death of his mother, Meursault is shown to be highly detached from the world around him. We don’t know if he’s always been this way, but it seems that way. The opening line of “Aujourd’hui, maman est morte” shows a kind of apathy that suggests Meursault is inclined to this detached nature already. Anyway, having shown essentially no grief, he begins a new relationship to whom he seems to have no strong feelings, he declines a new and better job on the grounds that it wouldn’t really change anything, and helps his neighbour write a highly abusive letter to a woman despite the potential for a retaliation. He goes on an outing to a beach with some people he knows and ends up killing a man on a beach in a moment of seeming disorientation. His trial, and in particular the court’s reaction to his total apathy, is the focus of the second half of the book. It ends with Meursault condemned partly due to this detachment from the world and thinking about the craziness of it all.</p>

			<p>Right now imagine how hard that is to comprehend in a second language…</p>

			<p>Meursault seems to be Camut’s way of telling us that there is no meaning behind most actions or results and that therefore most things are not worth caring too much about. He doesn’t care about his mother’s death. He doesn’t care about his romantic relationship. He doesn’t care about killing a man. He doesn’t care about his trial. He gives a huge shit about the fact he will be put to death for not caring which reinforces this message. If so many things can happen beyond rhyme or reason, how are we to understand or process the things people choose to care about? This is the only thing that works Meursault up. Not the things that happen to him but the things others choose to care about. This difference in personal experience and broader expectation is key throughout the story. Meursault always seems to be on the wrong side of what people expect of him. That he should be upset by his mother’s death (despite his insistence that she was lost to him anyway) or that he should want a new job (despite his belief that the job would not change his circumstances beyond the material anyway, and so on. As he comes to understand that he and society will never agree in the closing few pages he seems to accept it as unavoidable.</p>

			<p>The use of the legal system and its binaries to explain the seemingly random and absurd nature of the universe is a nice one. I initially studied law but found myself frustrated by seeming distinctions without difference. Everyone is totally outraged by the idea that Jaffa Cakes should attract VAT or not on the basis of whether they are truly cakes or mere biscuits. Similarly the “pasty tax” in the UK caused an outrage for something that actually tried to streamline an anachronism of the tax system. Camut uses this system, where the prosecutor can present such distaste at not just what Meursault did but his lack of remorse. This indifference is used as the main thrust of his pursuit of the death penalty for Meursault. Meursault himself sees this as absurd and simply will not engage with it, to his detriment. We see a similar relationship with the Chaplain as the book closes. The Chaplain cannot comprehend Meursault’s lack of faith or his lack of desire for forgiveness even in the face of death. As this conversation goes on, Meursault becomes angry at the questions put to him given his situation. So we see again the expectation of society (that Meursault should be remorseful and ask God and the people for forgiveness) contrasted in binary through the trial with his complete lack of emotional engagement with the situation.</p>

			<p>I’ll level with you - my understanding of L’étranger comes mainly from reading Spark Notes after struggling through the French. So I won’t go on with anymore trite observations on one of the most studied works of French literature. All I will say is that I got a greater sense of achievement from finishing this than any other book I’ve ever read. I always thought I sucked at languages despite a familial propensity to them so to have even my poor middling French that will just about get me through a holiday feels great. I’m more or less finishing up with reading for the year here. I’ve got a much busier professional end to the year than I was expecting which is taking most of my free time but I will probably do more of these next year. Though I do want to focus more on passing a French exam and getting back into playing piano where I spent this year getting back into guitar - so we’ll see.</p>

			<p>If anyone has read these through 2024, I hope they weren’t too shit. I wanted to get in the habit of thinking more about what I read, and I think I’ve achieved that.</p>
		</div>


    </div>
</template>


<script>

	export default {
		name: "LetrangerArticle"
	}

</script>


<style>

</style>