<template>
    <div>

		<div class="title">
			<p class="main">Nudge by Richard Thaler & Cass Sunstein</p>
			<div class="subheadings">
				<p class="published-date">13th June 2024</p>
			</div>
		</div>
		<div class="article">
			<p>I’m probably not the first person who works slightly in design and choice architecture to read Nudge by Richard Thaler and Cass Sunstein and think “Hey, I should implement some of this in my work”, but that is definitely what has happened as I’ve read this. Nudge promotes what its authors call “libertarian paternalism” - the idea that people should have the freedom to choose, but be given advice or “nudges” about what a good option might be. Just to set the tone as the authors do: the example they give is Google Maps directions. They are probably the best route, but your car or phone doesn’t punish you if you decide to do something different. It’s nudging you along what it deems the optimal path.</p>

			<p>Like usual, I’m going to try and shy away from summarising the whole book. That’s what ChatGPT is for. Instead, I want to focus on what I was able to take away from this book. I’m not new to popular behaviour psychology books. I usually get through one a year and learn all over again about the difference between rational economic actors of classical economics versus the bias prone disaster areas that are actual human decision makers. It’s astonishing to me that for centuries we proposed economic policies based on the idea that people making decisions for themselves are perfectly rational and educate themselves to the ideal level before choosing. We know that’s not true from day to day experience of our lives! Any rational actor would never eat a McDonalds when there is far more nutritional and cheaper food available elsewhere. But McDonalds is delicious (it is, do not fight me on this), relatively cheap, quick, easy, and very well established. It’s everywhere. It’s doing nudging itself.</p>

			<p>A quick aside to the classical economics screaming that I can’t possibly know that no rational actor would make that choice because I don’t know all their information. To that I say what everyone who has moved with modern economic theory says: The classical argument doesn’t allow for personal circumstances or preference. It stipulates pure rationality, and Mr Spock would never eat a double quarter pounder with cheese even though it’s amazing and terrible. Large fries please.</p>

			<p>But this is not an economics book. Not really anyone. It’s a book about choosing and how people do it. Some of those choices are economic (like picking a pension plan) but some are not especially economic (like deciding what excess to have on your car insurance). There is an example of rationality here that I do want to explore. Typically, when taking out insurance, I try to agree to the largest excess I can afford. Chances are, my house isn’t going to burn down and I’m not going to write my car off. If either of those things happen, only having a £200 excess versus a £500 one is not going to lessen the sting or the problem that much. But doing this reduces monthly premiums substantially. I only need to make it a couple of years without setting fire to my house or driving into a bollard to come out on top and, frankly, I back myself. I might get unlucky, but I probably won’t. Here’s the thing though, I started doing that after reading a book by Daniel Kahneman a couple of years ago which explained the same principle. People almost invariably take the lowest excess possible even when it is against their financial interests. Even if a higher excess would mean that, overall, they would pay less even in cases of extreme misfortune, they still take the lower option. Some of these are reasonable. A person's income might not support a higher excess or they may be generally financially insecure. But that won’t be everyone. Thaler and Sunstein suggest that we should nudge people slightly to consider the long term costs of their decisions, not just the “sticker shock” when purchasing.</p>

			<p>One further short example just because I found it interesting and I’ve done a total 180 on the subject while reading the book. I’ve long been a supporter of opt-out organ donation, the principle of presumed consent. The theory goes that by presuming consent, we get more organs. And indeed, in what has been called the most famous graph in behavioural psychology, we see that opt-in systems get about 15-20% of people to sign up, and opt-out systems often get over 99% of people to… not sign down? There is an issue though. Suppose you are a person who has not opted in. Your family will be asked if your organs can be donated and they can agree or veto. Suppose you have actively opted in. Decision made. Families can’t really object or at least are highly unlikely to. So there you have 15-20% of people who will definitely donate, and the rest who might. And you get to ask twice. Once when they are alive, and once after they pass away. Now flip it around and suppose a person has not opted out, so 99% of people. We do not presume consent and just start cutting out organs - we check. And again the family can agree or veto. The 1% we don’t ask, and that fine. So actually an opt-out system reduces the number of certain donations substantially. Thaler and Sunstein have been credited with advancing the cause of opt-out systems, a charge which they vigorously dispute. They instead propose Active Choosing - asking people at regular ordinary intervals to opt in, and increasing the opt-in rate. There are US states who do this with substantially lower organ waits by asking people to sign up when they renew their licence, or register to vote, or whatever. The law of unintended consequences in action.</p>

			<p>Lots of countries around the world have embraced nudging. Famously during Covid the UK Government had a behavioural insights group who proposed delaying lockdown because people would only tolerate it for a few weeks. That went well, didn’t it? But this shouldn’t put us off nudging, or at least thinking about choice architecture. One of the earliest examples in the book is illustrative: How should food be arranged in a school cafeteria? Even if you disagree that children should be encouraged to eat healthily, we know that placing things at eye level, or first in a queue, increases how often they are chosen. We can’t place them randomly, so we have to decide. It’s worth thinking about what outcome your default directs people to and to me this is the heart of the matter. Nudging is not about scolding people for “choosing incorrectly”. It’s about thinking when designing services “What am I unintentionally guiding towards here, and what would I rather guide towards given the option?” What is the best default? This is something anyone who sets other people up to make decisions should think about, and especially people who are doing this for a lot of people (such as product managers for the public sector).</p>


		</div>

    </div>
</template>


<script>

	export default {
		name: "NudgeArticle"
	}

</script>


<style>

</style>